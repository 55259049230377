import { Box, Collapse, IconButton, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { TextField, fetchUtils } from "react-admin";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import tokenManager from "../../../tokenManager";

const UserConditionList = ({ data }) => {

  const groupByCondition = (diagnosisList) => {
    return diagnosisList.reduce((acc, diagnosis) => {
      const { name, icd_code, status } = diagnosis
      if (!acc[name]) {
        acc[name] = {
          id: diagnosis.condition_id,
          user_id: diagnosis.user,
          name,
          feedbackEnabled: diagnosis.feedbackEnabled,
          diagnoses: []
        }
      } else if (!acc[name].feedbackEnabled && diagnosis.feedbackEnabled) {
        acc[name].feedbackEnabled = diagnosis.feedbackEnabled
      }

      acc[name].diagnoses.push({
        icd_code,
        icd_description: diagnosis.icd_description,
        status
      })

      return acc;
    }, {});
  }

  const groupedConditions = Object.values(groupByCondition(data)).sort((a:any, b:any) => {
    if (a.name.toLowerCase().includes('not supported')) return 1;
    if (b.name.toLowerCase().includes('not supported')) return -1;
    return a.name.localeCompare(b.name)
  });

  const [allOpen, setAllOpen] = useState(false)

  let initialOpenStates = {}
  groupedConditions.forEach((condition:any) => {
    initialOpenStates[condition.name] = false
  })
  const [openStates, setOpenStates] = useState(initialOpenStates)

  const toggleAllOpen = () => {
    const openStatesCopy = {...openStates}
    Object.keys(openStatesCopy).forEach((condition) => {
      openStatesCopy[condition] = !allOpen
    })
    setAllOpen(!allOpen)
    setOpenStates(openStatesCopy)
  }




  const ConditionRow = ({ record }) => {
    const [feedbackEnabled, setFeedbackEnabled] = useState(record.feedbackEnabled.toString())

    const toggleFeedbackEnabled = async (event) => {
      const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
        const httpClient = fetchUtils.fetchJson;
        const accessToken = tokenManager.getToken();

        const copyString = await httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/edit-condition-feedback-status`, {
            method: 'POST',
            credentials: 'include',
            user: {
                authenticated: true,
                token: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                user_id: record.user_id,
                condition_id: record.id,
                feedback: event.target.value === 'true' ? true : false
            }),
        }).catch((e)=> {
            console.log(`Error in billingsEdit:handleClickBilled()`)
            console.error(e)
            throw e
        }).then((response) => {
            if (response.json.error) {
                console.error(response.json.error)
                throw new Error(response.json.error)
            } else {
                setFeedbackEnabled(event.target.value)
            }
        })
    }

    const toggleOpen = () => {
      let copyOpenStates = {...openStates}
      copyOpenStates[record.name] = !copyOpenStates[record.name]
      if (copyOpenStates[record.name] && !allOpen) {
        setAllOpen(true)
      } else if (!copyOpenStates[record.name] && allOpen) {
        const openValues = Object.values(copyOpenStates)
        if (!openValues.includes(true)) {
          setAllOpen(false)
        }
      }
      setOpenStates(copyOpenStates)
    }
  
    return (
      <>
        <tr className='condition-row'>
          <td className="condition-expand-icon">
            <IconButton onClick={() => toggleOpen()}>
              {openStates[record.name] ?  <ExpandMoreIcon /> : <ExpandMoreIcon sx={{ transform: 'rotate(270deg)' }} />}
            </IconButton>
          </td>
          <td className="condition-name">
            {<TextField source="name" record={(!record.name.toLowerCase().includes('not supported')) ? record : {name: "Other Diagnosis Codes"}} />}
          </td>
          {(!record.name.toLowerCase().includes('not supported')) ? <td className="condition-feedback-status">
            <Select
              className={`feedback-select ${feedbackEnabled === 'true' ? 'feedback-enabled' : ''}`}
              value={feedbackEnabled}
              onChange={toggleFeedbackEnabled}
            >
              <MenuItem value={'true'}>Feedback Enabled</MenuItem>
              <MenuItem value={'false'}>Feedback Disabled</MenuItem>
            </Select>
          </td> : null}
        </tr>
        {openStates[record.name] && (
          <tr className='sublist-row'>
            <td colSpan={3}>
              <Collapse in={openStates[record.name]}>
                {/* <Box margin={2}> */}
                <Box className='diagnosis-sublist'>
                  {record.diagnoses.map((diagnosis) => (
                    <div key={diagnosis.icd_code} className='diagnosis-item'>
                      <span className="diagnosis-spacer"></span>
                      <span className="icd-code">{diagnosis.icd_code.toUpperCase()}</span>
                      <span className="icd-description">{diagnosis.icd_description}</span>
                      <span className={`status-label ${diagnosis.status}`}>{diagnosis.status.charAt(0).toUpperCase() + diagnosis.status.slice(1)}</span>
                    </div>
                  ))}
                </Box>
              </Collapse>
            </td>
          </tr>
        )}
      </>
    )
  }





  return (
    <div className='diagnosisList'>
      <table>
        <thead>
          <tr>
            <th>
              <IconButton onClick={() => toggleAllOpen()}>
                {allOpen ? <ExpandMoreIcon /> : <ExpandMoreIcon sx={{ transform: 'rotate(270deg)' }} />}
              </IconButton>
            </th>
            <th>
              Condition
            </th>
            <th>
              Status / Post-Appt PROs
            </th>
          </tr>
        </thead>
        <tbody>
          {groupedConditions.map((condition:any) => (
            <ConditionRow key={condition.name} record={condition} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default UserConditionList;
